import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"

class About extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    // TODO: move the content to markdown
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <h1>TODO</h1>
        <section>
          <h3>Features</h3>
          <ul>
            <li>
              Add tags to posts, and pages which can view all posts with a
              certain tag.
            </li>
            <li>Themes (like dark mode!).</li>
            <li>
              Option to justify or ragged-left (this is the current setting)
              text.
            </li>
            <li>
              Make it easier for users to understand that they need to manually
              navigate to the actual blog content from the landing page. Right
              now users can only click on the title, which only hints at
              clickability due to its colour. We can try to make it closer to
              something like <a href="https://dev.to">dev.to</a>.
            </li>
          </ul>
        </section>
        <section>
          <h3>Engineering</h3>
          <ul>
            <li>
              Transcode videos to multiple formats to support more users and
              compress videos down to an appropriate size because a lot of
              people have Australian internet. [This plugin might
              help](https://www.gatsbyjs.org/packages/gatsby-remark-videos/?=video).
            </li>
            <li>
              Add HTTPS on <Link to="/">dtam.me</Link> (it's enabled
              automatically on{" "}
              <a href="https://dualscyther.gitlab.io">dualscyther.gitlab.io</a>
              ).
            </li>
            <li>
              Write <Link to="/about/">about</Link> page and this page in
              markdown rather than where it is right now in the main source
              code.
            </li>
            <li>
              License content (i.e. blog content) differently (read: less
              permissively) to the website code.
            </li>
            <li>
              Remove typography.js from codebase. It was included as part of the{" "}
              <code>gatsby-starter-blog</code> Gatsby Starter, however, I
              haven't experienced the problem that it's trying to solve so I
              can't appreciate why it's useful, so it just adds additional
              complexity to the codebase.
            </li>
          </ul>
        </section>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
